import React from 'react';
import './Contact.css';

const Contact = () => {
    return (
        <div className="contact-page">
            <h1>Contact Us</h1>
            <p>We'd love to hear from you! Please fill out the form below or use any of the other contact methods listed.</p>

            <div className="contact-container">
                <div className="contact-form">
                    <h2>Get in Touch</h2>
                    <form>
                        <div className="form-group">
                            <label>Name</label>
                            <input type="text" placeholder="Your Name" required />
                        </div>
                        <div className="form-group">
                            <label>Email</label>
                            <input type="email" placeholder="Your Email" required />
                        </div>
                        <div className="form-group">
                            <label>Message</label>
                            <textarea placeholder="Your Message" required></textarea>
                        </div>
                        <button type="submit" className="submit-button">Send Message</button>
                    </form>
                </div>

                <div className="contact-info">
                    <h2>Our Office</h2>
                    <p>123 GreenPulse Avenue, Suite 100<br />City, Country, 12345</p>
                    <p>Email: contact@greenpulse.org</p>
                    <p>Phone: +123-456-7890</p>

                    <div className="map">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.835434507636!2d-122.41941548468158!3d37.77492927975862!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8085808c2c0b79e9%3A0x4c2547bdb64047ef!2sSan%20Francisco%2C%20CA%2C%20USA!5e0!3m2!1sen!2s!4v1618974292383!5m2!1sen!2s"
                            allowFullScreen=""
                            loading="lazy"
                            title="Google Maps Location"
                        ></iframe>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;
