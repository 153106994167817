import React from 'react';
import './Donate.css';

const Donate = () => {
    return (
        <div className="donate-page">
            <h1>Support Our Cause</h1>
            <p>Your generous donation helps us continue our vital work. Every contribution makes a difference.</p>

            <div className="donation-options">
                <h2>Choose Your Donation Amount</h2>
                <div className="amount-options">
                    <button className="donation-amount">$10</button>
                    <button className="donation-amount">$25</button>
                    <button className="donation-amount">$50</button>
                    <button className="donation-amount">$100</button>
                    <input type="number" placeholder="Enter Custom Amount" className="custom-amount" />
                </div>
            </div>

            <div className="payment-methods">
                <h2>Select Payment Method</h2>
                <div className="methods">
                    <div className="method-card">
                        <img src="paypal-logo.png" alt="PayPal" />
                        <p>PayPal</p>
                    </div>
                    <div className="method-card">
                        <img src="credit-card-logo.png" alt="Credit Card" />
                        <p>Credit Card</p>
                    </div>
                    <div className="method-card">
                        <img src="stripe-logo.png" alt="Stripe" />
                        <p>Stripe</p>
                    </div>
                </div>
            </div>

            <button className="donate-button">Donate Now</button>
        </div>
    );
};

export default Donate;
