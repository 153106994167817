// Homepage.js
import React from 'react';
import './Homepage.css';

import preservationImg from './images/preservation.png';
import wasteManagementImg from './images/waste-management.png';
import awarenessImg from './images/awareness.png';
import educationImg from './images/education.png';
import innovationImg from './images/innovation.png';
import logoImg from './images/logo.png';

// Define the Homepage component
const Homepage = () => {
    const initiatives = [
        { imgSrc: preservationImg, title: 'Environmental Preservation', stats: '20 Projects | 40K Volunteers' },
        { imgSrc: wasteManagementImg, title: 'Waste Management', stats: '25 Projects | 50K Volunteers' },
        { imgSrc: awarenessImg, title: 'Public Awareness', stats: '18 Campaigns | 25K Participants' },
        { imgSrc: educationImg, title: 'Education Programs', stats: '10 Programs | 15K Attendees' },
        { imgSrc: innovationImg, title: 'Sustainable Innovation', stats: '8 Projects | 12K Innovators' },

        { imgSrc: preservationImg, title: 'Environmental Preservation', stats: '20 Projects | 40K Volunteers' },
        { imgSrc: wasteManagementImg, title: 'Waste Management', stats: '25 Projects | 50K Volunteers' },
        { imgSrc: awarenessImg, title: 'Public Awareness', stats: '18 Campaigns | 25K Participants' },
        { imgSrc: educationImg, title: 'Education Programs', stats: '10 Programs | 15K Attendees' },
        { imgSrc: innovationImg, title: 'Sustainable Innovation', stats: '8 Projects | 12K Innovators' },
        { imgSrc: preservationImg, title: 'Environmental Preservation', stats: '20 Projects | 40K Volunteers' },
        { imgSrc: wasteManagementImg, title: 'Waste Management', stats: '25 Projects | 50K Volunteers' },
        { imgSrc: awarenessImg, title: 'Public Awareness', stats: '18 Campaigns | 25K Participants' },
        { imgSrc: educationImg, title: 'Education Programs', stats: '10 Programs | 15K Attendees' },
        { imgSrc: innovationImg, title: 'Sustainable Innovation', stats: '8 Projects | 12K Innovators' },
    ];

    // Define the AboutSection component within Homepage
    const AboutSection = () => {
        return (
            <section className="about-section">
                <h2>About GreenPulse Foundation</h2>
                <div className="about-content">
                    <div className="about-text">
                        <h3>Our Mission</h3>
                        <p>
                            At GreenPulse Foundation, we strive to create a sustainable future through community-driven initiatives focused on renovation, environmental preservation, and waste management.
                        </p>
                        <h3>Our Vision</h3>
                        <p>
                            Our vision is to foster a world where communities thrive in harmony with nature, ensuring a better quality of life for future generations.
                        </p>
                        <h3>Our Values</h3>
                        <ul>
                            <li>Integrity: Upholding the highest standards of ethics in all our actions.</li>
                            <li>Collaboration: Working together with communities, partners, and volunteers to maximize our impact.</li>
                            <li>Innovation: Embracing creative solutions to tackle environmental challenges.</li>
                            <li>Respect: Valuing diversity and promoting inclusivity in all our initiatives.</li>
                        </ul>
                    </div>
                    <div className="about-image">
                        <img src={logoImg} alt="GreenPulse Foundation" />
                    </div>
                </div>
            </section>
        );
    };

    return (
        <div className="homepage">
            {/* Hero Section */}
            <section className="hero-section">
                <div className="hero-content">
                    <h1>Join Us in Renovating, Preserving, and Sustaining Our Environment</h1>
                    <p>At GreenPulse Foundation, we believe in creating a sustainable future through community-driven initiatives focused on renovation, environmental preservation, and waste management.</p>
                    <button className="cta-button">Get Involved 🌍</button>
                </div>
            </section>

            {/* Initiatives Section */}
            <section className="initiatives-section">
                <h2>Our Key Initiatives</h2>
                <div className="initiatives-carousel">
                    <div className="scroll-content">
                        {initiatives.map((initiative, index) => (
                            <div key={index} className="initiative-card">
                                <img src={initiative.imgSrc} alt={initiative.title} />
                                <h3>{initiative.title}</h3>
                                <p>{initiative.stats}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {/* About Section */}
            <AboutSection /> {/* Include the AboutSection here */}
        </div>
    );
};

export default Homepage;
