import React from 'react';
import './Projects.css';

const projects = [
    {
        id: 1,
        title: 'Sustainable Energy Initiative',
        description: 'A project focused on developing sustainable energy solutions for rural communities.',
        imageUrl: 'https://img.freepik.com/premium-photo/city-park-plaza-with-modern-architectural-elements-green-spaces_1314467-23539.jpg?ga=GA1.1.264430115.1727359540&semt=ais_hybrid',
        link: '#'
    },
    {
        id: 2,
        title: 'Urban Green Spaces',
        description: 'An initiative to create and maintain green spaces in urban areas to improve air quality and reduce heat.',
        imageUrl: 'https://img.freepik.com/premium-photo/power-lines-sunset_1030899-8995.jpg?ga=GA1.1.264430115.1727359540&semt=ais_hybrid',
        link: '#'
    },
    {
        id: 3,
        title: 'Water Conservation Project',
        description: 'A project aimed at conserving water resources through innovative technology and community education.',
        imageUrl: 'https://img.freepik.com/premium-photo/celebrating-ozone-day-background_1007543-36312.jpg?ga=GA1.1.264430115.1727359540&semt=ais_hybrid',
        link: '#'
    },
    // Add more project objects as needed
];

const Projects = () => {
    return (
        <section className="projects-section">
            <h2>Our Projects</h2>
            <div className="projects-container">
                {projects.map(project => (
                    <div key={project.id} className="project-card">
                        <img src={project.imageUrl} alt={project.title} />
                        <div className="project-card-content">
                            <h3>{project.title}</h3>
                            <p>{project.description}</p>
                            <a href={project.link}>Learn More</a>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
}

export default Projects;
