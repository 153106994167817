import React from 'react';
import './Blog.css';

const Blog = () => {
  return (
    <div className="blog">
      <h2>Our Blog</h2>
      <p>Blog posts about our activities and community outreach will be found here.</p>
      {/* Add blog posts here */}
    </div>
  );
};

export default Blog;
