import React from 'react';
import './Founders.css';

const Founders = () => {
  return (
    <div className="founders">
      <h2>Meet Our Founders</h2>
      <p>Information about the founders of the GreenPulse Foundation will be displayed here.</p>
      {/* Add founder profiles here */}
    </div>
  );
};

export default Founders;
