import React from 'react';
import './Header.css';
import logo from './images/logo.png'; // Update with the correct path to your logo image
import Navbar from './Navbar'; // Import the Navbar component

const Header = () => {
    return (
        <header className="header">
            <div className="logo-container">
                <img src={logo} alt="GreenPulse Logo" className="logo" />
                <h1 className="site-name">GreenPulse Foundation</h1>
            </div>
            <Navbar /> {/* Include the Navbar here */}
            
        </header>
    );
};

export default Header;
