// Research.js
import React, { useState } from 'react';
import './Research.css'; // Ensure you create a CSS file for styling

const Research = () => {
  // Dummy articles data
  const articles = [
    {
      title: 'The Importance of Ecosystems',
      author: 'John Doe',
      date: 'September 1, 2024',
      content: 'Ecosystems play a crucial role in maintaining the balance of our environment. They provide essential services such as clean air, water, and fertile soil.',
    },
    {
      title: 'Innovations in Renewable Energy',
      author: 'Jane Smith',
      date: 'September 5, 2024',
      content: 'Renewable energy innovations are transforming our approach to sustainability. This article explores the latest advancements in solar and wind technologies.',
    },
    {
      title: 'Climate Change and Its Impacts',
      author: 'Alice Johnson',
      date: 'September 10, 2024',
      content: 'Climate change is one of the most pressing issues of our time. Understanding its impacts is vital for developing effective solutions.',
    },
  ];

  // State for search term
  const [searchTerm, setSearchTerm] = useState('');

  return (
    <div className="research-page">
      <h2>Research Articles</h2>
      {/* Subheader with categories and search bar */}
      <div className="subheader">
        <h3>Categories of Contents</h3>
        <div className="categories">
          <span className="category">Ecosystems</span>
          <span className="category">Renewable Energy</span>
          <span className="category">Climate Change</span>
        </div>
        <input 
          type="text" 
          placeholder="Search articles..." 
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-bar"
        />
      </div>
      <div className="articles">
        {articles.filter(article => 
          article.title.toLowerCase().includes(searchTerm.toLowerCase())
        ).map((article, index) => (
          <div key={index} className="article">
            <h4>{article.title}</h4>
            <p className="author-date">{article.author} | {article.date}</p>
            <p>{article.content}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Research;
