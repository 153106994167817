import React, { useState, useEffect } from 'react';
import './Navbar.css'; // Import your CSS

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkScreenSize = () => {
            setIsMobile(window.innerWidth < 600);
        };

        checkScreenSize();
        window.addEventListener('resize', checkScreenSize);

        return () => window.removeEventListener('resize', checkScreenSize);
    }, []);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav className="navbar">
            {isMobile ? (
                <>
                    <button onClick={toggleMenu} className="menu-toggle">
                        &#9776; {/* Hamburger icon */}
                    </button>
                    {isOpen && (
                        <ul className="navbar-mobile-menu">
                            <li><a href="/">Home</a></li>
                            <li><a href="/projects">Projects</a></li>
                            <li><a href="/research">Research</a></li>
                            <li><a href="/donate">Donate</a></li>
                            <li><a href="/contact">Contact</a></li>
                        </ul>
                    )}
                </>
            ) : (
                <ul className="navbar-menu">
                    <li><a href="/">Home</a></li>
                    <li><a href="/projects">Projects</a></li>
                    <li><a href="/research">Research</a></li>
                    <li><a href="/donate">Donate</a></li>
                    <li><a href="/contact">Contact</a></li>
                </ul>
            )}
        </nav>
    );
};

export default Navbar;
